<template>
  <div class="detail">
    <md-result-page
      :img-url="require('@/assets/abd/image/null.png')"
      v-if="reportok === ''"
      subtext="要不然刷新试试？"
    />
    <div v-if="reportok === 'ok'" style="-webkit-overflow-scrolling: touch">
      <div class="detailtop" :style="'background:'+getStorage('theme','')">
        <img
          :src="
            sex == 1
              ? require('@/assets/abd/image/woman.png')
              : require('@/assets/abd/image/male.png')
          "
          class="imgtouxiang"
          alt="123"
        />
        <span class="name">{{ name }}</span>
      </div>
      <div class="btn secondary_bg" :style="'background:'+getStorage('theme','')+'!important'" @click="upDatareport" v-if="!noShow">
        <div class="bt">
          <span class="iconfont iconshuaxin-"></span>
          <span>更新报告</span>
        </div>
      </div>
      <!-- tag="li"
                replace
                :to="
                  noShow
                    ? ''
                    : { path: '/details', query: { reportno: item.reportno } }
                " -->
      <div class="detailContent">
        <div class="content">
          <div class="detailList">
            <ul>
              <li
                @click="topolicyreport(item)"
                class="userPolicyInfo"
                v-for="(item, index) in baodanList"
                :key="index"
              >
                <p class="policyname">{{ item.name }}</p>
                <p class="policyNum">保单号：{{ item.policyno }}</p>
                <p class="policyNum">保险公司：{{ item.companyname }}</p>
                <p class="policyNum">保险期间：{{ item.liabdate }}</p>
              </li>
            </ul>
          </div>
          <!----------------------------------- 基本保障统计 ---------------------------------->
          <div class="analysis">
            <div class="tou">
              <!-- <div class="fangkuai"></div> -->
              <span class="iconfont context iconwj-cptj" :style="'color:'+getStorage('theme','')+'!important'"></span>
              <span class="base" :style="'color:'+getStorage('theme','')">基本保障统计</span>
              <span class="part">单位:万元</span>
              <ve-histogram
                :data="chartData"
                :width="jbwidth"
                :height="jbheight"
                :settings="chartSettings"
                :extend="chartExtend"
                class="histogram"
                :colors="color"
              ></ve-histogram>
            </div>
            <!----------------------------------- 保障明细 ---------------------------------->
            <div class="tou">
              <ul>
                <li
                  class="partLi"
                  v-for="(item, index) in typeDetails"
                  :key="index"
                >
                  <div class="father" v-if="item.code.length != 0">
                    <!-- <div class="fangkuai"></div> -->
                    <span
                      class="iconfont context iconyiliao1"
                      v-if="item.name == '重疾'"
                    ></span>
                    <span
                      class="iconfont context iconyiwaixian"
                      v-if="item.name == '意外身故'"
                    ></span>
                    <span
                      class="iconfont context iconyiliao_"
                      v-if="item.name == '疾病身故'"
                    ></span>
                    <span
                      class="iconfont context iconicon_zhongjilvtong"
                      v-if="item.name == '意外医疗'"
                    ></span>
                    <span
                      class="iconfont context iconyiliaowenjian"
                      v-if="item.name == '住院医疗'"
                    ></span>
                    <span
                      class="iconfont context iconshuiyanyanglao"
                      v-if="item.name == '养老'"
                    ></span>
                    <span
                      class="iconfont context iconjiaoyu"
                      v-if="item.name == '教育'"
                    ></span>
                    <span
                      class="iconfont context iconlicai"
                      v-if="item.name == '理财'"
                    ></span>
                    <span class="base">{{ item.name }}</span>
                    <div class="partdetail">
                      <table class="c-table" id="showBox">
                        <thead>
                          <tr>
                            <th>保险责任</th>
                            <th>保额（万元）</th>
                            <th>保险期间</th>
                          </tr>
                        </thead>
                        <tr v-for="(v, i) in item.code" :key="i">
                          <td>{{ v.liab.liab_desc }}</td>
                          <td>{{ v.liab.liab_exp }}</td>
                          <td>{{ v.liab.liabdate }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!----------------------------------- 保费支出 ---------------------------------->
            <div class="tou">
              <!-- <div class="fangkuai"></div> -->
              <span class="iconfont context iconzhichu" :style="'color:'+getStorage('theme','')+'!important'"></span>
              <span class="base" :style="'color:'+getStorage('theme','')">保费支出</span>
              <span class="part">单位：元</span>
              <ve-bar
                :data="chartDataM"
                :width="bfwidth"
                :height="bfheight"
                :settings="chartSettingsM"
                :extend="chartExtend1"
                class="histogram"
                :colors="color"
              >
                <span class="sunMoney" :style="'color:'+getStorage('theme','')">年度总保费{{ this.sumMonthMoney }}</span>
              </ve-bar>
            </div>
            <!----------------------------------- 客户保障缺口分析 ---------------------------------->
            <div class="tou">
              <!-- <div class="fangkuai"></div> -->
              <span class="iconfont context iconRectangleCopy" :style="'color:'+getStorage('theme','')+'!important'"></span>
              <span class="base" :style="'color:'+getStorage('theme','')">客户保障缺口分析</span>
              <!-- <span class="part">单位：万元</span> -->
              <div class="partdetail">
                <table class="c-table" id="showBox" v-if="bugths.length !== 0">
                  <thead>
                    <tr>
                      <th rowspan="2" :style="'background:'+getStorage('theme','')">保障类型</th>
                      <th colspan="3" :style="'background:'+getStorage('theme','')">保额（万元）</th>
                    </tr>
                    <tr>
                      <th style="width:25%" :style="'background:'+getStorage('theme','')">应有保障</th>
                      <th style="width:25%" :style="'background:'+getStorage('theme','')">已有保障</th>
                      <th style="width:25%" :style="'background:'+getStorage('theme','')">缺口</th>
                    </tr>
                  </thead>
                  <tr v-for="(item, index) in bugths" :key="index">
                    <td>{{ item.name1 }}</td>
                    <td>{{ item.recommend }}</td>
                    <td>{{ item.maxmoney }}</td>
                    <td>
                      <font class="red" :style="'color:'+getStorage('theme','')">{{ item.money1 }}</font>
                    </td>
                  </tr>
                </table>
                <p v-if="bugths.length === 0">
                          <span class="nodata">暂无数据</span>
                </p>
              </div>
            </div>
            <!----------------------------------- 病种缺口分析 ---------------------------------->
            <div class="tou">
              <ul>
                <li class="partLi">
                  <div class="father">
                    <span class="iconfont context iconfenxi" :style="'color:'+getStorage('theme','')+'!important'"></span>
                    <span class="base" :style="'color:'+getStorage('theme','')">病种缺口分析</span>
                    <div class="partdetail">
                      <table class="b-table" v-if="diseasesNo.length !== 0">
                        <thead>
                          <tr>
                            <th>暂缺病种</th>
                          </tr>
                        </thead>
                        <tr v-for="(item, index) in diseasesNo" :key="index">
                          <td>{{ item.diseasesname }}</td>
                        </tr>
                      </table>
                       <p v-if="diseasesNo.length === 0">
                          <span class="nodata">暂无数据</span>
                       </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
           <!-- 家庭对应产品信息 -->
    <div class="tou" v-if="isuser||ensurebloo">
      <div class="partLi">
        <span class="leftdiv context iconfont iconbaoxianchanpin" :style="'color:'+getStorage('theme','')+'!important'"></span>
        <span class="base" :style="'color:'+getStorage('theme','')">保障方案</span>
      </div>
      <div v-for="(item,index) of this.details" :key="index" >
        <div class="product" v-if="isuser||item.jhsno" :style="'border:1px solid '+getStorage('theme','')">
          <div class="producttop">
          <p class="producttopname" :style="'background:'+getStorage('theme','')">{{item.panme}}的保障方案推荐</p>
        </div>
          <div v-for="(cpdata,index) of item.cplist" :key="index" :class="item.hcon?'productcentop':''">
            <div class="productcen" v-if="cpdata.checked">
              <div class="productcen-lf">
                <img :src="msossurl + cpdata.iconhttp" alt="">
              </div>
              <div class="productcen-rg">
                <div class="productcen-rg-cen">
                  <div class="productcen-rg-top">
                    <p>{{cpdata.cpname}}</p>
                  </div>
                  <div class="productcen-rg-bom">
                    <p>首年保费：{{cpdata.mount}}元</p>
                    <p class="productcenp">保额：{{cpdata.amount}}</p>
                  </div>
                </div>
              </div>
            </div>
        <!-- <div class="productcen" @click="particulars"> -->
          </div>
          <div class="premium">
            <p >首年总保费：<span :style="'color:'+getStorage('theme','')">{{item.premiumnum}}元</span></p>
          </div>
        <div class="productbom">
          <div :class="isuser?'productbomlf':'productbomlff'">
            <md-button size="large"
                   type="primary"
                   :style="'background:'+getStorage('theme','')"
                   @click="makeout(item)">{{isuser?'预览保障方案':'查看保障方案'}}</md-button>
          </div>
          <div class="productbomrg" v-if="isuser">
            <md-button size="large"
                   type="primary"
                   :style="'background:'+getStorage('theme','')"
                   @click="cpdataedit(item,'geren')" >编辑</md-button>
          </div>
        </div>
      </div>
        </div>
    </div>
            <!----------------------------------- 经纪人名片 ---------------------------------->
            <div class="tou">
              <div class="father">
                <span class="iconfont context iconmingpian1" :style="'color:'+getStorage('theme','')+'!important'"></span>
                <span class="base" :style="'color:'+getStorage('theme','')">经纪人名片</span>
              </div>
              <div class="partdetail border" :style="'border:1px solid '+getStorage('theme','')">
                <div class="infos">
                  <span>姓名:</span>
                  <div class="man">{{ jjname }}</div>
                </div>
                <div class="infos">
                  <span>手机号:</span>
                  <div>
                    <a class="man" :href="'tel:' + jjmobile">{{ jjmobile }}</a>
                  </div>
                </div>
              </div>
            </div>
            <dig
              :show.sync="isupdatemsg"
              title="编辑经纪人名片"
              :istext="false"
            >
              <md-field>
                <md-input-item
                  title="姓名"
                  v-model="nickname"
                  clearable
                  placeholder="请输入经纪人姓名"
                />
                <md-input-item
                  title="手机"
                  v-model="mobile"
                  clearable
                  placeholder="请输入经纪人手机号"
                />
              </md-field>
              <md-button
                size="large"
                class="context_bg"
                type="primary"
                @click="submitmsg"
                >提交</md-button
              >
            </dig>
          </div>
          <div class="wrapperlast">
      <div class="advice">
        <div class="caption1">
          <span class="leftdiv context iconfont iconbeizhuyitianxie" :style="'color:'+getStorage('theme','')+'!important'"></span>
          <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">建议</span>
          <div class="bj_btn">
            <p class="iconfont iconbeizhu2 remark_color"
               @click="showMessageBox"
               v-if="!noShow"></p>
          </div>
        </div>
        <div class="expandingArea"
             id="textarea">
          <div class="remark" :style="'border:1px solid '+getStorage('theme','')">
            <pre class="contwords">{{ text }}</pre>
            <pre class="contwords nodatalist" v-if="text===''">暂无数据</pre>
          </div>
        </div>
      </div>
    </div>
    <dig :show.sync="isremark"
         title="优化建议"
         :istext="false">
      <textarea class="remark"
                v-model="remark"
                placeholder="请输入您的建议"></textarea>
      <div class="showbtn">
        <md-button size="large"
                   type="primary"
                   :style="'background-color:'+getStorage('theme','')+'!important'"
                   @click="submitremark">提交</md-button>
      </div>
    </dig>
          <div class="explain tou">
            <p>上述保险责任统计标准</p>
            <p>系统默认统计基本保障额度</p>
            <div class="c-bar">
              <p class="barline"><em class="iconfont iconyiliao1" :style="'color:'+getStorage('theme','')"></em>重疾</p>
              <p class="barword">
                默认统计普通重疾确诊赔付的额度。例如：一般重疾赔50万，特定重疾额外赔50万。系统默认统计的重疾额度为50万。
              </p>
            </div>
            <div class="c-bar">
              <p class="barline">
                <em class="iconfont iconyiwaixian" :style="'color:'+getStorage('theme','')"></em>意外
              </p>
              <p class="barword">
                默认统计普通意外身故和意外伤残都可以赔付的额度，以及疾病身故可以赔付的保障额度。如果单是意外身故或者意外全残赔的话则不统计。例如：统计的意外为50万，意思是意外身故可以赔50万，意外伤残可以按照比例赔付，最高赔50万，意外全残可以赔50万。假如保单责任项下另有航空意外身故赔付1000万，这个保额不会统计在意外责任项下。
              </p>
            </div>
            <div class="c-bar">
              <p class="barline">
                <em class="iconfont iconyiliaowenjian" :style="'color:'+getStorage('theme','')"></em>医疗
              </p>
              <p class="barword">
                默认统计普通疾病或者重疾住院可以报销的额度，只是某种疾病住院才赔的则不统计。
              </p>
            </div>
            <div class="c-bar">
              <p class="barline">
                <em class="iconfont iconshuiyanyanglao" :style="'color:'+getStorage('theme','')"></em>养老
              </p>
              <p class="barword">默认统计产品名称或责任明确为养老的产品。</p>
            </div>
            <div class="c-bar">
              <p class="barline"><em class="iconfont iconjiaoyu" :style="'color:'+getStorage('theme','')"></em>教育</p>
              <p class="barword">默认统计产品名称或责任明确为教育金的产品。</p>
            </div>
            <div class="c-bar">
              <p class="barline"><em class="iconfont iconlicai" :style="'color:'+getStorage('theme','')"></em>理财</p>
              <p class="barword">默认统计分红类带收益的产品。</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="hw_sty" @click="toShare" v-if="!noShow">
        <span class="iconfont iconfenxiang"></span>
        <span>分享</span>
      </div> -->

      <dig title="报告访问链接及密码" :istext="false" :show.sync="isDetail">
        <div class="links">
          <div class="txt">
            【保单整理】您有一份【{{$route.query.name}}】的客户分析报告,请戳以下链接并输入密码查看：{{redirect_uri}}/sharereport
            (验证码：{{ verify }})。请您妥善保管本消息，切勿随意转发给其他人。
          </div>
          <div class="bt copybtn">
            <md-button
              size="large"
              data-clipboard-target="#foo"
              class="copy context_bg copybtn1"
              type="primary"
              :disabled="sharebtn"
              >{{ btnText }}</md-button
            >
          </div>
          <div class="prompt">
            点“复制”后，在与客户本人（客户保单的投保人或被保人）的微信的聊天窗口中“粘贴”此消息并发送即可
          </div>
          <div id="foo">
            【保单整理】您有一份【{{$route.query.name}}】的客户分析报告,请戳以下链接并输入密码查看：
            {{
              `${redirect_uri}/sharereport?t=${params} (验证码：${verify})`
            }}
            。请您妥善保管本消息，切勿随意转发给其他人。
          </div>
        </div>
      </dig>
    </div>
    <div class="navsetting" v-if="!noShow&&user.rytype!=='Y'&&fromwhere=='app'">
      <div class="backbtn" :style="'height:'+'33%'" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome" :style="'height:'+'33%'" @click="backtohomepage" v-if="personkey==='0'">
        <span class="iconfont iconhome"></span>
      </div>
      <div class="shareicon" :style="'height:'+'33%'" @click="toShare">
        <span class="iconfont iconiconfontfenxiang2"></span>
      </div>
    </div>
    <div class="navsetting2" v-if="user.rytype==='Y'&&!noShow">
       <div class="backbtn" :style="'height:'+'100%'" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
    </div>
    <div v-if="jointlytrue">
      <md-popup v-model="jointlytrue">
        <div class="jointlymold">
          <jointly ref="jointly" :form="form" @jointlymode="jointlymode"></jointly>
        </div>
    </md-popup>
    </div>
  </div>
    <!-- <div class="backbtn" @click="backtopage" v-if="!noShow">back</div>
    <div class="backtohome" @click="backtohomepage" v-if="!noShow&&personkey==='0'">home</div> -->
  </div>
</template>

<script>
import { prodlist } from '@/api/jhs/productlist'
import { getuserlist, personAnalysis, updatemsg } from '@/api/abd/customer'
import { createAnalysis,editpolicyremarks, lookAnalysis, looksharereport, selectReportData } from '@/api/abd/family'
import { addChineseUnit, setStorage, getStorage, AppShare } from '@/lib/util'
import { Base64 } from 'js-base64'
import ClipboardJS from 'clipboard'
import dialog from '@/components/dialog'
import { Toast } from 'mand-mobile'
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
import jointly from '../../../jhs/productcheck/component/jointlyrespon'
import {
  DoRecord
} from '@/api/abt/customerOperation/common/index'
import initWebSocket from '@/mixins/websock'
import store from '@/store'
const { redirect_uri, cvu } = config
export default {
  name: 'detail',
  mixins: [initWebSocket],
  components: {
    jointly,
    dig: dialog
  },
  data () {
    this.chartSettings = {
      labelMap: {
        money: '保险金额'
      },
      metrics: ['money'],
      dimension: ['name']
    }
    // dr-abd/image
    this.color = ['#11579D']
    this.chartExtend = {
      legend: {
        textStyle: {
          // dr-abd/image
          color: '#11579D'
        },
        selectedMode: false // 取消图例上的点击事件
      },
      xAxis: {
        axisLabel: {
          margin: 5,
          fontSize: 12,
          interval: 0, // 横轴信息全部显示
          formatter: function (value) {
            // x轴的文字改为竖版显示
            var str = value.split('')
            return str.join('\n')
          }
        }
      }
    }
    this.chartExtend1 = {
      grid: {
        containLabel: true
      },
      legend: {
        selectedMode: false // 取消图例上的点击事件
      },
      xAxis: {
        axisLabel: {
          fontSize: 8
        },
        boundaryGap: true,
        nameLocation: 'end'
      },
      yAxis: {
        axisLabel: {
          fontSize: 10
        }
      },
      series: {
        barCategoryGap: '40%'
      }
    }
    this.chartSettingsM = {
      labelMap: {
        monthMoney: '月度保费'
      },
      metrics: ['monthMoney'],
      dimension: ['month']
    }
    return {
      text: '',
      remark: '',
      isremark: false,
      jbwidth: '',
      jbheight: '',
      bfwidth: '',
      bfheight: '',
      sharebtn: false,
      reportok: 'ok',
      name: '',
      sex: '',
      selected: '1',
      cardno: '',
      cardtype: '',
      baodanList: '',
      chartData: {
        columns: ['name', 'money'],
        rows: []
      },
      chartDataM: {
        columns: ['month', 'monthMoney'],
        rows: []
      },
      MonthMoney: [],
      typeDetails: [],
      chartvalue: [],
      diseasesNo: [],
      money: [],
      month: [],
      sumMonthMoney: '',
      bugths: [
        {
          name1: '',
          recommend: '',
          maxmoney: '',
          money1: ''
        }
      ],
      verify: null,
      ishiden: false,
      reportno: '',
      yzm: '',
      params: '',
      btnText: '一键复制',
      isDetail: false,
      noShow: false,
      isupdate: false,
      isupdatemsg: false,
      jjname: '',
      jjmobile: '',
      nickname: '',
      mobile: '',
      shortUrl: '',
      comid: getStorage('u_s', {}).comid,
      /* 行为追踪 */
      user: {},
      empno: '',
      rytype: '',
      suserid: '',
      personkey: '',
      empuserid: '',
      fromwhere: '',
      proddatalist: [],
      details: [],
      isuser: false,
      userempno: '',
      ensure: 0,
      ensurebloo: false,
      form: [],
      jointlytrue: false
    }
  },
  created () {
    this.msossurl = cvu
    this.fromwhere = this.$route.query.fromwhere
    this.cardno = this.$route.query.cardno
    this.cardtype = this.$route.query.cardtype
    this.familyno = this.$route.query.familyno
    this.personkey = this.$route.query.personkey
    this.empuserid = this.$route.query.empuserid
    this.user = getStorage('u_s', {})
    this.userempno = this.user.empno ? this.user.empno : ''
    this.empno = this.$route.query.empno && this.$route.query.empno !== '' ? this.$route.query.empno : this.user.empno
    this.rytype = this.$route.query.rytype ? this.$route.query.rytype : this.user.rytype
    if (this.personkey === '1') {
      this.rytype = 'W'
    }
    if (this.rytype === 'Y') this.rytype = 'W'
    this.suserid = this.$route.query.suserid ? this.$route.query.suserid : this.user.userid
    this.userid = this.user.userid
    this.name = this.$route.query.name
    if (this.$route.query.type || this.$route.query.sharetype) {
      this.noShow = true
    }
    if (this.personkey === '1' || this.user.rytype === 'Y' && !this.$route.query.sharetype) {
      createAnalysis({
        cardno: this.cardno,
        cardtype: this.cardtype,
        familyno: this.familyno,
        empuserid: this.$route.query.empuserid
      }).then(res => {
        this.reportok = 'ok'
        if (res.data.data.myPolicy && res.data.data.myPolicy.length === 0) {
          this.reportok = ''
        }
        this.baodanList = res.data.data.myPolicy
        this.baodanList = this.baodanList.filter(el => el != null)
        this.reportno = res.data.data.reportno
        this.yzm = res.data.data.yzm
        store.state.app.isloading = false
        this.isupdate = true
        this.$route.query.reportno = res.data.data.reportno
        this.$route.query.yzm = res.data.data.yzm
        if (this.fromwhere !== 'app' && this.user.rytype === 'W') {
          this.sharewx()
        }
        this.selectCustomer()
      })
      // selectReportData({ familyno: this.$route.query.familyno, comid: this.user.comid, reporttype: '1', cardno: this.$route.query.cardno, empno: this.$route.query.empno }).then(res => {
      //   this.reportno = res.data.data.reportno
      //   this.yzm = res.data.data.yzm
      //   this.sharewx()
      //   this.selectCustomer()
      // })
    } else {
      if (this.$route.query.sharetype && this.user.userid !== this.suserid) {
        looksharereport({ userid: this.user.userid, reportno: this.$route.query.reportno, yzm: this.$route.query.yzm, comid: this.$route.query.comid }).then(res => { console.log(res) })
        if (this.fromwhere !== 'app' && this.rytype === 'W') {
          this.sharewx()
        }

        // if (this.fromwhere !== 'app') {
        //   this.behaviorRecord()
        // }
        this.selectCustomer()
      } else {
        this.$route.query.type ? this.selectCustomer() : this.upDatareport()
      }
    }
    new ClipboardJS('.copy').on('success', (e) => {
      this.btnText = '复制成功'
      this.sharebtn = true
      e.clearSelection()
    })
    /* 分享 */
  },
  mounted () {
    // eslint-disable-next-line no-irregular-whitespace
    $(document).on('focusout', function () {
      // 软键盘收起的事件处理
      window.scroll(0, 0)
    })
  },
  beforeUpdate () {
    this.$nextTick(() => {
      this.jbwidth = '100%'
      this.bfwidth = '100%'
      this.jbheight = '400px'
      this.bfheight = '400px'
    })
  },
  methods: {
    sharewx () {
      let info = getStorage('u_s', {})
      if (!this.$route.query.type) {
        this.behaviorRecord()
      }
      let fxstr = {
        suserid: this.suserid,
        empno: this.empno,
        otype: '2',
        btagcode: '14',
        rytype: this.rytype,
        remark: this.$route.query.name,
        scmap: {
          reportno: this.reportno ? this.reportno : this.$route.query.reportno
        }
      }
      let shareurl = `${redirect_uri}/detail?yzm=${this.yzm ? this.yzm : this.$route.query.yzm}&comid=${info.comid ? info.comid : this.$route.query.comid}&reportno=${this.reportno ? this.reportno : this.$route.query.reportno}&sharetype=00&empno=${this.empno}&recordtype=14&suserid=${this.user.userid}&rytype=${this.rytype}&cardno=${this.$route.query.cardno}&cardtype=${this.$route.query.cardtype}&familyno=${this.$route.query.familyno}&name=${this.name}&memid=${this.$route.query.memid}`
      if (this.user.rytype != 'W') {
        // 不是业务员
        wechatshare('', '', '', '', '', '', true)
      } else {
        wechatshare(
          this.$route.query.name + '的客户分析报告',
          '您的好友【' + info.nickname + '】邀请您查看【' + this.$route.query.name + '】客户分析报告',
          info.headimg,
          encodeURI(shareurl),
          redirect_uri,
          fxstr
        )
      }
    },
    // 修改经纪人信息
    showUpdateMsg () {
      if (!this.noShow) {
        this.isupdatemsg = true
        this.nickname = this.jjname
        this.mobile = this.jjmobile
      }
    },
    // 保存经纪人信息
    submitmsg () {
      let date = {
        abdMembUser: {
          nickname: this.nickname,
          mobile: this.mobile
        }
      }
      updatemsg(date).then(res => {
        this.jjname = this.nickname
        this.jjmobile = this.mobile
      })
      this.isupdatemsg = false
    },
    toShare () {
      const jsonstring = {
        url: `${redirect_uri}/detail?yzm=${this.yzm ? this.yzm : this.$route.query.yzm}&comid=${this.user.comid ? this.user.comid : this.$route.query.comid}&reportno=${this.reportno ? this.reportno : this.$route.query.reportno}&sharetype=00&empno=${this.empno}&recordtype=14&suserid=${this.user.userid}&rytype=${this.rytype}&cardno=${this.$route.query.cardno}&cardtype=${this.$route.query.cardtype}&familyno=${this.$route.query.familyno}&name=${this.name}&memid=${this.$route.query.memid}`,
        title: this.$route.query.name + '的客户分析报告',
        description: '您的好友【' + this.user.empname + '】邀请您查看【' + this.$route.query.name + '】客户分析报告',
        logoUrl: ''
      }
      AppShare(jsonstring)
      // this.btnText = '一键复制'
      // this.isDetail = !this.isDetail
    },
    // 获取保单列表
    getParams () {
      let Data = this.$route.query
      this.name = Data.name
      this.sex = Data.sex
      let data = {
        abdPolicynoPerson: {
          cardno: this.cardno
        },
        empno: this.empno
      }
      // getuserlist(data).then(res => {
      //   if (this.baodanList == null) {
      //     // 生成报告
      //     personAnalysis({ cardno: this.cardno, cardtype: '10' })
      //   }
      //   this.baodanList = res.data.rows
      // })
    },
    upDatareport () {
      this.safeguard()
      createAnalysis({
        cardno: this.cardno,
        cardtype: this.cardtype,
        familyno: this.familyno,
        empuserid: ''
      }).then(res => {
        if (res.data.data.myPolicy && res.data.data.myPolicy.length === 0) {
          this.reportok = 'ok'
        }
        this.baodanList = res.data.data.myPolicy
        this.baodanList = this.baodanList.filter(el => el != null)
        this.reportno = res.data.data.reportno
        this.yzm = res.data.data.yzm
        this.text = res.data.data.remarks
        this.isupdate = true
        this.$route.query.reportno = res.data.data.reportno
        this.$route.query.yzm = res.data.data.yzm
        if (this.fromwhere !== 'app' && this.user.rytype === 'W') {
          this.sharewx()
        }
        this.selectCustomer()
      })
    },

    // 查询用户保险数据
    selectCustomer () {
      this.safeguard()
      this.bugths = []
      this.comid = getStorage('u_s', {}).comid
      lookAnalysis({ yzm: this.yzm ? this.yzm : this.$route.query.yzm, reportno: this.reportno ? this.reportno : this.$route.query.reportno, comid: this.comid ? this.comid : this.$route.query.comid }).then(res => {
        // 经纪人名片信息
        this.reportok = 'ok'
        this.jjname = res.data.data.CustomerReport.empname
        this.jjmobile = res.data.data.CustomerReport.emphone

        let Data = res.data.data.CustomerReport
        this.empno = res.data.rows.empno
        this.getParams()
        this.verify = res.data.rows.yzm
        this.MonthMoney = Data.MonthMoney
        this.chartDataM.rows = this.MonthMoney // 月度总保费，获取的是每个月的值
        this.sumMonthMoney = Data.sumMonthMoney // 年度总保费
        this.typeDetails = Data.TypeDetails
        this.chartvalue = Data.MaxMoney
        this.chartData.rows = this.chartvalue // 基本保障统计，获取的是最高值
        let Data1 = Data.MaxMoney // 缺口分析下的 已有保障
        let Data2 = Data.Recommend // 缺口分析下的 应有保障
        this.baodanList = Data.myPolicy
        this.baodanList = this.baodanList.filter(el => el != null)
        // if (Data2 === '获取年龄失败') { Toast.failed('保单尚未解析，请等待') }
        if (Data.diseasesNo) {
          this.diseasesNo = Data.diseasesNo
        }
        Data1.forEach((item, index) => {
          if (item.name === Data2[index].name) {
            let result =
              item.money - Data2[index].money > 0
                ? 0
                : Data2[index].money - item.money
            this.bugths.push({
              name1: item.name,
              recommend: Data2[index].money,
              maxmoney: item.money,
              money1: result
            })
          }
        })
        this.$route.query.comid = this.comid
        this.$route.query.suserid = this.suserid
        this.$route.query.empno = this.empno
        this.params = Base64.encodeURI(
          JSON.stringify({
            path: {
              p: this.$route.path,
              query: this.$route.query
            },
            verify: this.verify
          })
        )
      })
      if (this.isupdate === true) {
        Toast.info('更新成功')
      }
    },
    // 查看保障
    safeguard () {
      this.proddatalist = [
        {
          familyno: this.$route.query.familyno,
          memid: this.$route.query.memid,
          pname: this.$route.query.name
        }
      ]
      let data = {
        comid: getStorage('u_s', {}).comid || this.$route.query.comid,
        empno: this.empno,
        userempno: this.userempno,
        list: this.proddatalist
      }
      prodlist(data).then(res => {
        this.isuser = res.data.data.isuser
        this.details = res.data.data.list
        this.ensure = 0
        for (let item in this.details) {
          let a = 0
          this.details[item].cplist.forEach(v => {
            if (v.checked) {
              a++
              this.ensure++
            }
          })
          if (a == 0) {
            this.details[item].hcon = true
          }
        }
        if (this.ensure != 0) {
          this.ensurebloo = true
        }
        for (let item in this.details) {
          let premiumnum = 0
          this.details[item].cplist.forEach(v => {
            if (v.checked) {
              premiumnum += parseFloat(v.mount)
            }
          })
          this.details[item].premiumnum = premiumnum
        }
      })
    },
    // 查看产品列表对应信息
    particulars () {
      console.log('查看详情')
    },
    // 编辑产品列表对应信息
    cpdataedit (val, mold) {
      if (mold == 'geren') {
        let prod = []
        for (let item in this.proddatalist) {
          if (val.memid == this.proddatalist[item].memid) {
            prod.push(this.proddatalist[item])
          }
        }
        setStorage('proddatalist', prod)
        this.$router.push({
          path: '/productcheck',
          query: {
            mold: mold,
            personkey: this.$route.query.personkey,
            empuserid: this.$route.query.empuserid,
            familyname: this.$route.query.familyname,
            fromwhere: this.$route.query.fromwhere,
            type: this.$route.query.type,
            comid: this.comid,
            familyno: this.$route.query.familyno,
            memid: val.memid,
            cardtype: this.$route.query.cardtype,
            cardno: this.$route.query.cardno,
            name: this.$route.query.name,
            empno: this.$route.query.empno
          }
        })
      }
    },
    jointlymode () { // 显示计划书
      this.jointlytrue = false
    },
    // 查看计划书
    makeout (val) {
      if (val.jhsno) {
        this.jointlytrue = true
        this.form = JSON.parse(val.jhsno)
      } else {
        Toast.failed('尚未生成保障方案!')
      }
    },
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        otype: '1',
        btagcode: '14',
        rytype: this.rytype,
        remark: this.$route.query.name,
        scmap: {
          reportno: this.reportno ? this.reportno : this.$route.query.reportno
        }
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
      })
    },
    backtopage () {
      this.$router.push({ path: '/customer',
        query: {
          familyname: this.$route.query.familyname,
          familyno: this.$route.query.familyno,
          personkey: this.$route.query.personkey,
          empno: this.$route.query.empno || '',
          empuserid: this.$route.query.empuserid,
          fromwhere: this.$route.query.fromwhere
        } })
    },
    backtohomepage () { this.$router.push({ path: '/bdtg', query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true } }) },
    topolicyreport(row){
      this.$router.push({
        path: 'policydetail',
        query:{
          yzm: 'abd666',
          comid: this.user.comid,
          reportno: row.reportno,
          policyno: row.policyno,
          from: 'upload',
          type: 'pc',
          fromwhere: this.$route.query.fromwhere
        }
      })
    },
    showMessageBox () {
      if (!this.noShow) {
        this.isremark = !this.isremark
        this.remark = this.text
      }
    },
    submitremark () {
      editpolicyremarks({
        reportno: this.famreportno ? this.famreportno : this.$route.query.reportno,
        remarks: this.remark,
        comid: getStorage('u_s', {}).comid
      }).then(() => {
        this.isremark = !this.isremark
        this.text = this.remark
        Toast.info('更新成功')
      })
    },
  }
}
</script>
<style scoped lang="stylus">
    .detail {
        padding-bottom: 154px;
        width: 100vw;
        height: 100vh;
        overflow-x: hidden
    }
    /* .tou .iconfont {
        font-size: 38px;
        color: #3296fa;
    } */
    #foo {
        opacity 0
        height: 0
    }
    .links .txt {
        border: 1px dashed #81b7da;
        padding: 40px 20px;
        font-size: 36px;
    }
    .links .bt {
        padding: 20px;
    }
    .links button {
        background: #036eb5;
    }
    .prompt {
        color: #848484;
        font-size: 36px;
    }
    .bt {
        display: flex;
        align-items: center;
        width: 200px;
        justify-content: center;
        margin: 0 auto;
        color: #ffffff;
    }
    .btn {
        padding: 20px 10px;
        text-align: center;
        box-shadow: 3px 8px 15px 0 rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(186, 216, 232, 0.4);
        margin: 20px 25px;
        border-radius: 15px;
        background-color: #5ab9d9;
        span{
          font-size 34px
        }
    }
    .copybtn {
        width: 100%;
    }
    .detailtop {
        width: 100%;
        height: 360px;
        // background-image: url("../../../../assets/abd/image/bg.png");
        background-color color-primary
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
        padding-top: 100px;
    }
    .hr {
        background-color: rgb(235, 234, 234);
        border: none;
        height: 1px;
        margin: 20px 0;
    }
    .imgtouxiang {
        width: 100px;
        height: 100px;
    }
    .name {
        display: block;
        width: 200px;
        text-align: center;
        color: #fff;
        margin: 0 auto;
    }
    .detailNav {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -40%;
        margin-top: 250px;
        width: 80%;
        height: 220px;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 2px 2px 8px #ccc;
    }
    .phone {
        padding: 20px;
        font-size: 28px;
    }
    .iconphone {
        font-size: 28px;
    }
    .iconbeizhu1 {
        font-size: 26px;
    }
    .remark {
        padding-left: 20px;
        font-size: 28px;
    }
    .xiugai {
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
    }
    .xiugaiSpan {
        font-size: 26px;
    }
    .iconicon {
        font-size: 32px;
        float: right;
    }
    .userPolicyInfo {
        position: relative;
        background-color: #fff;
        padding: 30px 40px;
        /* box-shadow: 0 8px 10px rgba(186, 216, 232, 0.4); */
        /* border: 1px solid rgba(186, 216, 232, 0.4); */
        border-bottom: 1px solid #dfe0e1;
    }
    .userPolicyInfo:last-child {
        border-color: transparent;
    }
    .userPolicyInfo > h2 {
        letter-spacing: 1px;
    }
    .mint-navbar .mint-tab-item.is-selected {
        border-color: #8094f7;
        color: #8094f7;
    }
    .userPolicyState {
        text-align: center;
        margin: auto;
        position: absolute;
        display: inline-block;
        width: 45px;
        right: 28px;
        top: -80px;
        color: #fff;
        transform-origin: bottom center;
        transform: rotate(45deg);
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .baozhang {
        width: 0;
        height: 0;
        position: absolute;
        right: 0;
        top: 0;
        font-size: xx-small;
        border-top: 80px solid #00b570;
        border-left: 80px solid transparent;
    }
    .tou {
        position: relative;
        width: 100%;
        background-color: #fff;
        /* border-radius: 20px; */
        padding: 30px 40px;
        margin: 20px 0 0;
        /* box-shadow: 0 8px 10px rgba(186, 216, 232, 0.4);
        border: 1px solid rgba(186, 216, 232, 0.4); */
    }
    .tou .iconfont {
  font-size: 20px;
  color: color-primary
}
    .fangkuai {
        display: inline-block;
        width: 9px;
        height: 30px;
        background-color: #3296fa;
        position: relative;
        top: 4px;
    }
    .base {
        font-size: 40px;
        color: color-primary;
        margin-left: 10px;
        /* font-weight: bold; */
    }
    .bj_btn {
        color: black;
        float: right;
    }
    .icontianxie {
        font-size: 35px !important;
    }
    /* yanse */
    .border {
        border: 1px solid color-primary; /*边框*/
        border-radius: 10px;
    }
    .infos {
        /*padding-top: 20px;*/
        display: flex;
        align-items: center;
        font-size: 30px;
        padding: 20px 0;
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid #ebebeb;
    }
    .infos div {
        float: right;
        text-align: right;
        flex: 1;
    }
    .man {
        color: #969696;
    }
    .words {
        margin: 0 0 30px 5%;
    }
    .update {
        width: 100%;
        padding: 20px;
        min-height: 200px;
    }
    .update span {
        margin-left: 10%;
    }
    .update input {
        width: 60%;
        padding: 20px 20px;
        border: 1px solid rgba(186, 216, 232, 0.4);
    }
    .sunMoney {
        display: inline-block;
        position: absolute;
        top: 6%;
        left: 50%;
        margin-left: -112px;
        font-size: 24px;
        color: color-primary;
    }
    .part {
        font-size: 22px;
        float: right;
    }
    .c-table {
        border-collapse: collapse;
        margin: 0 auto;
        width: 100%;
    }
    .c-table td,
    .c-table tr,
    .c-table th {
        border: 1px solid #e3f0f7;
        height: 70px;
        color: #646464;
        font-size: 24px;
        text-align: center;
    }
    .c-table thead th {
        /* font-weight: 400; */
        background-color: color-primary;
        width: 100px;
        font-size: 28px;
        color: #ffffff;
        height: 70px;
    }
    .b-table thead {
        width: 100%;
    }
    .b-table td,
    .b-table tr,
    .b-table th {
        padding-left: 20px;
        height: 70px;
        color: #646464;
        font-size: 24px;
        border: 1px solid #ddebfa;
    }
    .b-table thead th {
        /* font-weight: 400; */
        background-color: color-primary;
        width: 100%;
        font-size: 28px;
        color: #ffffff;
        height: 70px;
    }
    .c-table td,
    .c-table tr,
    .c-table th {
        font-size: 28px
    }
    .c-table tr:nth-child(odd) {
        background: #fff;
    }
    .c-table tr:nth-child(even) {
        background: #f5fafa;
    }
    .b-table {
        width: 100%;
    }

    .partLi {
        margin-bottom: 30px;
    }

    .c-bar {
        margin-top: 30px;
        letter-spacing: 1px;
        background-color: #fff;
    }
    .explain {
        background-color: #fff;
        padding: 53px;
    }
    .explain > p:nth-child(1) {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 38px;
        color: #383838;
        letter-spacing: 1px;
        /* font-weight: 500; */
    }
    .explain > p:nth-child(2) {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 30px;
        color: #7c7c7c;
        /* font-weight: normal; */
        letter-spacing: 1px;
        margin-top: 10px;
    }
    .barline {
        border-bottom: 1px dashed #d3d3d3;
        height: 70px;
        line-height: 70px;
        color: #383838;
        font-size: 35px;
    }
    .barword {
        margin-top: 25px;
        font-size: 32px;
        color: #7c7c7c;
        line-height: 40px;
    }
    .barline > .iconfont {
        font-size: 18px;
        margin-right: 10px;
    }
    .iconshangyeshouxian {
        font-size: 45px !important;
    }
    .iconjiaoyu_fuzhi {
        font-size: 28px !important;
    }
    .policyname {
        font-size: 38px;
        color: #383838;
        margin-bottom: 20px;
        /* font-weight: bold; */
    }
    .policyNum {
        font-size: 32px;
        color: #969696;
        margin-bottom: 10px;
    }
    .histogram {
        margin-top: 30px;
    }
    .partdetail {
        margin-top: 30px;
    }
    .centercontent {
        text-align: center;
        margin-top: 20px;
    }
    .centercontent span:nth-child(2n + 1) {
        display: inline-block;
        width: 2px;
        height: 18px;
        background-color: #bfbfbf;
        position: relative;
        bottom: 10px;
    }
    .centercontent span:nth-child(2n) {
        display: inline-block;
        width: 2px;
        height: 28px;
        background-color: #bfbfbf;
    }
    .red {
        color: #d4484c;
        font-size: 24px;
    }
.hw_sty {
  height: 70px;
  width: 170px;
  position: fixed;
  background-color: color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  right: 0;
  bottom: 40%;
  border-radius: 50px 0 0 50px
}
.iconfenxiang{
  font-size: 40px;
  display: inline-block;
  display: flex;
  height: 60px;
  align-items: center;
}
    .nodata{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px ;
        font-size: 30px;
        color: #7c7c7c
    }
    .part{
        font-size: 34px
        }
.secondary_bg{
  background-color color-primary
}
.copybtn1{
  background-color: color-primary !important
  margin  14px 0
}
.navsetting{
  width 10vw
  height 30vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 50%
}
.navsetting::after{
  content: '分享'
  color white
  display block
  display flex
  justify-content center
  align-items center
  font-size 30px
  border-radius  0 0 10px 10px
  width 10vw
  letter-spacing 5px
  background-color color-primary
}
.navsetting1{
  width 10vw
  height 20vw
  border-radius 10px
  background-color black
  opacity 0.38
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.backbtn{
  width 80%
  margin 0 auto
  border-bottom 0.8px solid rgba(255,255,255,0.5)
  display flex
  justify-content center
  align-items center
  span{
    width 100%
    display flex
    justify-content center
    align-items center
    color white
    font-size 50px
  }
}
.backtohome{
  width 80%
  margin 0 auto
  display flex
  justify-content center
  align-items center
  span{
    display flex
    justify-content center
    align-items center
    width 100%
    color white
    font-size 50px
  }
}
.shareicon{
  width 80%
  margin 0 auto
  display flex
  justify-content center
  border-top 0.8px solid rgba(255,255,255,0.5)
  align-items center
  span{
    display flex
    justify-content center
    align-items center
    width 100%
    color white
    font-size 48px
  }
}
.navsetting2{
  width 10vw
  height 10vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.product{
border 1px solid color-primary
border-radius 15px
margin-bottom 30px
}
.producttop{
  color #fff
  height 80px
  background-color color-primary
  border-bottom 1px solid color-primary
}
.producttopname{
  padding-left 40px
  height 80px
  line-height 80px
}
.premium p{
  color #797979
  padding-left 40px
  height 80px
  line-height 80px
}
.premium span{
  color red
}
  .productcentop{
    height 40px
  }
  .productcen{
    display flex
    height 250px;
    background  #fff
    border-bottom 20px solid #eee
  }
  .productcen-lf{
    float left
    width 30%
    height 100%
    text-align center
    position relative
  }
  .productcen-lf img{
    border-radius 5px
    position absolute //相对定位
    width 180px
    height 160px
    top 0
    left 0
    right 0
    bottom 0
    margin auto //使其垂直居中
  }
  .productcen-rg{
    float left
    width 70%
    height 100%
    position relative
  }
  .productcen-rg-cen{
    position absolute //相对定位
    height 160px
    top 0
    left 0
    right 0
    bottom 0
    margin auto //使其垂直居中
  }
  .productcen-rg-top{
    height 50%
  }
  .productcen-rg-top p {
    padding-top 3%
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  }
  .productcen-rg-bom{
    display flex
  }
  .productcenp{
    margin-left 10px
  }
  .productcen-rg-bom p{
    padding-top 3%
    font-size 26px
    color #808080
  }
  .productbom{
    display flex
  }
  .productbomlff{
    width 100%
  }
  .productbomlf,.productbomrg{
    width 49.5%
  }
  .productbomrg{
    margin-left 1%
  }
  .jointlymold{
    width 100%
    height 100%
  }
  .wrapperlast {
  background: #fff;
  padding: 45px 45px 60px;
  line-height: 60px;
  margin-top: 40px;
}
.caption1 {
  width: 100%;
  margin: 0 0 30px;
}
.caption1 .iconfont{
  font-size: 20px
}
.remark {
  border: 1px solid color-primary;
  width: 100%;
  height: 80%;
  min-height: 200px;
  border-radius: 15px;
  font-size: 32px
}
.nodatalist{
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  color: #aaa;
  font-size: 35px;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 30px;
}
.showbtn {
  margin-top: 30px;
}
</style>
