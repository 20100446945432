<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2019-12-12 16:20:31
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-08-26 14:54:59
 -->
<template>
  <div class="mainbody"
       v-if="!showmainbody">
    <div class="topclose"
         :style="{ backgroundColor: theme }">
      <p>保障方案详情</p>
      <span class="close"
            @click="showjointly"><img src="@/assets/jhs/img/cancel.png" /></span>
    </div>
    <div class="plan"
         :style="{ backgroundColor: theme }">
      <div class="plan-top">
        <div class="banner">
          <img :src="form.Price"
               alt="" />
        </div>
      </div>
      <div class="plan-cen">
        <div class="basic">
          <div class="basic-top clearfix">
            <ul>
              <li>
                <p>首年总保费</p>
                <span :style="{ color: theme }"
                      v-if="form.khinfo">{{ form.khinfo.allmount }}元</span>
              </li>
            </ul>
          </div>
          <div class="basic-bom">
            <div class="module-interv">
              <div class="table_width-title">
                <table>
                  <tr class="tr_background">
                    <th class="th_public costother"
                        :style="{
                        backgroundColor: theme,
                        border: '1px solid ' + theme
                      }">
                      产品名称
                    </th>
                    <th class="th_public costother"
                        :style="{
                        backgroundColor: theme,
                        border: '1px solid ' + theme
                      }">
                      保险金额
                    </th>
                    <th class="th_public costother"
                        :style="{
                        backgroundColor: theme,
                        border: '1px solid ' + theme
                      }">
                      缴费年限
                    </th>
                    <th class="th_public costother"
                        :style="{
                        backgroundColor: theme,
                        border: '1px solid ' + theme
                      }">
                      年缴保费
                    </th>
                  </tr>
                  <tr v-for="(item, index) of form.ZxInfo"
                      :key="index">
                    <td class="tdbg">{{ item.prodname }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.yearnum }}</td>
                    <td>{{ item.yearmoney }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!convertshow">
          <div class="safeguard"
               v-for="(item, index) of form.zrList"
               :key="index">
            <div class="safeguard-top">
              <div class="safeguard-topce">
                <p>{{ item.name }}</p>
                <span class="types"
                      @click="types(item)"
                      v-if="item.ishasbz">病种</span>
              </div>
            </div>
            <div class="safeguard-bom">
              <div class="safeguard-bomp"
                   v-for="(ic, i) of item.data"
                   :key="i">
                <p class="marker">
                  {{ ic.liabdesc
                  }}<span class="spanblue"
                        :style="{ color: theme }">{{ ic.liabexp
                    }}{{ ic.liabatype == "2" ? "" : "元起" }}</span>
                </p>
                <p class="cccp">{{ ic.prodname }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="convertshow"
             class="red">
          <div class="safeguard"
               v-for="(item, index) of form.cpList"
               :key="index">
            <div class="safeguard-top">
              <p>{{ item.name }}</p>
            </div>
            <div class="safeguard-bom">
              <div class="safeguard-bomp"
                   v-for="(ic, i) of item.data"
                   :key="i">
                <p class="marker">
                  {{ ic.liabdesc
                  }}<span class="spanblue"
                        :style="{ color: theme }">{{ ic.liabexp
                    }}{{ ic.liabatype == "2" ? "" : "元起" }}</span>
                </p>
                <p class="cccp">{{ ic.enumname }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="clause">
          <div class="clausetop"
               @click="terms">
            <p>
              条款详情<img src="@/assets/jhs/img/next.png"
                   alt=""
                   v-if="!showterms" /><img src="@/assets/jhs/img/down.png"
                   v-if="showterms" />
            </p>
          </div>
          <div class="safelist-bom"
               v-if="showterms">
            <div class="safeguard-bomp"
                 v-for="(term, i) of form.tkList"
                 :key="i">
              <p class="markerr"
                 @click="markerpdf(term)"
                 v-if="term.tkname">
                <img src="@/assets/jhs/img/pdff.png" />{{ term.tkname
                }}<span class="markerpdf"
                      v-if="term.tkurl"><img src="@/assets/jhs/img/next.png"
                       alt="" /></span>
              </p>
            </div>
          </div>
        </div>
        <div class="safeguard">
          <div class="safeguard-top">
            <p class="fegx">风险提示</p>
          </div>
          <div class="safeguard-bom">
            <div class="safeguard-bomp">
              <p class="dot">
                风险提醒：投保人在投保犹豫期后解除合同会遭受一定损失，具体保单利益以保险合同为准
              </p>
              <p class="dot">
                温馨提示：以上演示说明为本平台对上述产品的理解便于保险从业人员学习，交流，演示数据仅供参考，以实例为准。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="plan-bom">
        <div class="plan-bomcen">
          <div class="plan-bomcen-cen">
            <div class="plan-bomcen-img">
              <img :src="form.ywyinfo.headimg"
                   alt="" />
            </div>
            <div class="plan-bomcen-txt">
              <p>{{ form.ywyinfo.cardempname }}</p>
            </div>
            <div class="plan-bomcen-btn">
              <p>{{ form.ywyinfo.cardmobile }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="broadside">
        <div @click="convert"
             :style="{ backgroundColor: theme }">
          <p v-if="!convertshow">按险种展示</p>
          <p v-if="convertshow">按责任展示</p>
        </div>
      </div> -->
      <div class="broadside">
        <div @click="convert(1)"
             :style="convertshow==false?{'backgroundColor':theme}:''"
             :class="convertshow==false?'Currentpage':'Nopage'">
          <p>按险种展示</p>
          <!-- <p v-if="convertshow">按责任展示</p> -->
        </div>
        <div @click="convert(2)"
             :style="convertshow==true?{'backgroundColor':theme}:''"
             :class="convertshow==true?'Currentpage':'Nopage'">
          <!-- <p v-if="!convertshow">按险种展示</p> -->
          <p>按责任展示</p>
        </div>
        <!-- <div class="iconc" v-if="fromwhere" @click="transmit">
          <span class="iconfont iconiconfontfenxiang2"></span>
        </div>-->
      </div>
    </div>
    <md-popup v-model="isPopupShow"
              position="right"
              ok-text="ok"
              cancel-text="cancel">
      <div class="md-example-popup md-example-popup-right">
        <div class="dangerlist">
          <div>
            <div class="dangerlistce"
                 v-if="clausedata">
              <div class="dangerlistcetop">
                <div class="dangerlistcetopl">
                  <span class="dangerlistcetoplspan"></span>
                  <p>病种详情</p>
                </div>
                <div class="dangerlistcetopr"
                     @click="mistake">
                  <img src="@/assets/jhs/img/cuo.png"
                       alt="" />
                </div>
              </div>
              <div v-for="(item, ina) in pickerData"
                   :key="ina"
                   @click="clause(ina)"
                   class="dangerlistcebom">
                <p :class="item.clickren ? 'dangerlistcebomb' : ''">
                  {{ item.disname }}
                </p>
                <img src="@/assets/jhs/img/next.png" />
              </div>
            </div>
            <div class="bobrick"
                 v-if="!clausedata"
                 v-model="clausedatada">
              <div class="bobrickl"
                   @click="getback">
                <p><img src="@/assets/jhs/img/zuo.png" />返回</p>
              </div>
              <div class="bobrickc">
                <p><span>【</span>{{ clausedatada.disname }}<span>】</span></p>
              </div>
              <div class="bobrickr"
                   @click="mistake">
                <img src="@/assets/jhs/img/cuo.png"
                     alt="" />
              </div>
            </div>
          </div>
          <div class="bobrickbom"
               v-if="!clausedata"
               v-model="clausedatada">
            <md-scroll-view :scrolling-x="false">
              <div v-for="(ic,
                i) in clausedatada.abtAbdModelClassLiabDisnolistList"
                   :key="i"
                   class="dangerlistcebombi">
                <p>
                  <span>{{ i + 1 }}.</span>{{ ic.disname }}
                </p>
              </div>
            </md-scroll-view>
          </div>
        </div>
      </div>
    </md-popup>
  </div>
  <div v-else
       class="showmainbodyc">
    <span @click="showmainbody = false"
          class="close"><img src="@/assets/jhs/img/cancel.png" /></span>
    <iframe class="prism-player"
            :src="file"
            width="100%"></iframe>
    <!-- <pdf
      v-for="i in numPages"
      :key="i"
      :page="i"
      :src="pdfUrl"
      style="width: 100%; height: auto;"
      @num-pages="pageCount = $event"
    ></pdf> -->

  </div>
</template>
<script>
import pdf from 'vue-pdf'
import { Picker, Toast } from 'mand-mobile'
import { returnreport } from '@/api/jhs/productlist/index'
import initWebSocket from '@/mixins/websock'
import { getStorage } from '@/lib/util'
import config from '@/config'
const { cvu } = config
export default {
  props: {
    form: {
      type: Object
    }
  },
  components: {
    pdf,
    [Picker.name]: Picker
  },
  mixins: [initWebSocket],
  data () {
    return {
      imgurl: "",
      file: '',
      // pdfUrl: "",
      // pageCount: 0,
      // numPages: 0,
      picurl: "",
      clausedata: true,
      clausedatada: {},
      /* 行为追踪 */
      isPickerShow1: false,
      user: {},
      empno: '',
      rytype: '',
      suserid: '',
      fromwhere: '',
      convertshow: false,
      isPopupShow: false,
      showsyr: false,
      showterms: true,
      showmainbody: false,
      form: {
        ZxInfo: [],
        khinfo: {
          allmount: ''
        },
        ywyinfo: {
          headimg: '',
          cardempname: '',
          cardmobile: ''
        }
      },
      pickerDefaultIndex: [],
      dataa: {
      },
      pickerData: []
    }
  },
  created () {
    this.theme = getStorage('theme', '')
    this.imgurl = cvu
    this.mold = this.$route.query.mold
  },
  methods: {
    showjointly () {
      this.$emit('jointlymode', false)
    },
    convert (val) {
      if (val == 1) {
        this.convertshow = false;
      } else if (val == 2) {
        this.convertshow = true;
      }
    },
    terms () { // 条款详情
      this.showterms = !this.showterms
    },
    types (val) { // 险种
      let list = []
      for (let item in val.data) {
        let obj = {
          liabcode: '',
          prodcode: ''
        }
        obj.liabcode = val.data[item].liabcode
        obj.prodcode = val.data[item].prodcode
        list.push(obj)
      }
      let data = {
        list: list,
        comid: this.user.comid
      }
      returnreport(data).then(res => {
        if (Object.keys(res.data.data).length !== 0) {
          this.pickerData = res.data.data
          for (let item in this.pickerData) {
            this.pickerData[item].clickren = false
          }
          this.isPopupShow = true
          this.clausedata = true
        } else {
          Toast.failed('暂无病种信息!')
        }
      })
    },
    danger (val) { // 责任数据
      for (let item of val) {
        item.value = item.liabcode
        item.label = item.liabdesc
        item.children = item.abtAbdModelClassLiabDisnolistList
        for (let ic of item.children) {
          ic.value = ic.discode
          ic.label = ic.disname
          ic.children = ic.abtAbdModelClassLiabUndislistList
          for (let ie of ic.children) {
            ie.value = ie.undiscode
            ie.label = ie.undisname
          }
        }
      }
      this.ce = [val]
      this.isPickerShow1 = true
    },
    clause (ina) { // 险种显示
      this.clausedatada = this.pickerData[ina]
      this.clausedata = false
      this.$forceUpdate()
    },
    async loadPdfHandler () {
      this.pdfUrl = pdf.createLoadingTask(this.markerpdfurl)
      this.pdfUrl.then(pdf => {
        this.numPages = pdf.numPages
      })
    },
    markerpdf (val) {
      // 下载pdf
      this.markerpdfurl = this.imgurl + val.tkurl;
      this.file = `${window.location.origin}/pdf/web/viewer.html?file=${encodeURIComponent(this.markerpdfurl)}#page=1`
      // this.numPages = 0;
      // this.loadPdfHandler();
      this.showmainbody = true;
    },
    mistake () {
      this.isPopupShow = false
    },
    getback () {
      this.clausedata = true
    }
  }
}
</script>
<style lang="stylus" scoped>
.md-example-popup {
    position relative
    font-size 28px
    font-family DINPro
    font-weight 500
    box-sizing border-box
    text-align center
    background-color #FFF
}
 .md-example-popup-right{
    height 100%
    width 80vw
    display flex
    align-items center
    }
  .mainbody{
    overflow-x hidden
    background color-primary
    width 100vw
  }
  .plan{
      height 100%
      background color-primary
  }
  .plan-top{
      height 20vh
  }
  .banner{
      text-align center
  }
  .banner img{
      height 20vh
  }
  .plan-cen{
      height 100%
      width 95vw
      margin 0 auto
      position relative
      top -30px
  }
  .basic, .safeguard, .clause{
      background #fff
      border-radius 5px
      padding  20px 0
      margin-bottom  30px
  }
  .clausetop{
      position relative
  }
  .clausetop p{
      height 44px
      line-height 44px
      text-align center
  }
  .clausetop img{
      position absolute
      width 44px
      height 44px
      top 50%
      margin-top -22px
  }
  .clausetop .marker{
      margin-left 20px
  }
  .basic-top{
      height 40%
  }
  .basic-top ul {
      height 100%
      list-style none
  }
  .basic-top ul i{
      float left
      width 2px
      height 60px
      margin-top 30px
      background #e0e0e0
  }
  .basic-top ul li{
      float left
      margin-top 20px
      width 100%
      text-align center
  }
  .basic-top ul li span{
      color color-primary
      font-weight 600
  }
  .basic-top ul li p{
      color #393939
      font-size 34px
  }
  .basic-bom{
      margin-top 50px
  }
  .module-interv table{
      border 0
      cellspacing 0
      cellpadding 0
      margin 0 auto
  }
  .module-interv table td{
      font-size 30px
      border 1px solid #efefef
      height 80px
      text-align center
  }
  .module-interv table .tdbg{
      background #eaf1f9
  }
  .module-interv table tr,.module-interv table th{
      border 1px solid color-primary
  }
  .tr_background{
    background-color color-primary
  }
  .th_public{
    height 80px
    text-align center
    font-size 30px
    color #ffffff
  }
  .costother{
   min-width 190px
  }
  .clearfix:after,.clearfix:before{
      content: "";
      display: table;
  }
  .clearfix:after{
    clear: both;
  }
  .clearfix{
    *zoom: 1;
  }
  .safeguard-top{
      height 100px
      line-height 100px
      text-align center
      padding-bottom 20px
      border-bottom 1px solid #e0e0e0
  }
  .safeguard-topce{
      position relative
      width 90%
      margin 0 auto
  }
  .types{
      width 150px
      position absolute
      font-size 25px
      right 10px
      top 20px
      line-height 50px
      border 1px solid color-primary
      color color-primary
  }
  .safeguard-top .fegx{
      color #ccc
  }
  .safeguard-bom{
      width 90%
      margin 0 auto
  }
  .safelist-bom p{
    height 50px
    line-height 50px
  }
  .safeguard-bom p{
      margin 15px 0
      word-wrap:break-word;
  }
  .safeguard-bomp{
      position relative
      margin 30px 0
  }
  .markerr img{
    float left
    width 50px
    height 50px
    line-height 50px
  }
  .safeguard-bomp .markerpdf{
      width 50px
      height 50px
      line-height 50px
      float right
      color color-primary
  }
  .marker::before {
        content ""
        width 20px
        height 20px
        background color-primary
        border-radius 50%
        position absolute
        margin-top 19px
        margin-left -25px
    }
    .dot::before {
        content ""
        width 15px
        height 15px
        background #cccccc
        border-radius 50%
        position absolute
        margin-top 10px
        margin-left -25px
    }
  .safeguard-bom .spanblue{
      padding-left 10px
      color color-primary
      font-weight 700
      font-size 40px
  }
  .safeguard-bom .cccp{
      margin 0
      font-size 30px
      color #9d9d9d
  }
  .dot{
      font-size 28px
      color #cccccc
  }
  .plan-bom{
      height 120px
      background #fff
  }
  .plan-bomcen{
      width 90vw
      height 120px
      margin 0 auto
      position relative
  }
  .plan-bomcen-cen {
      width 100%
      height 100px
      display flex
      position absolute
      top 50%
      margin-top -50px
  }
  .plan-bomcen-img{
      width 14%
      height 100%
      display flex
      align-items:center; /*实现垂直居中*/
  }
  .plan-bomcen-txt{
      height 100%
      width 80%
      display flex
      align-items:center; /*实现垂直居中*/
  }
  .plan-bomcen-txt p{
      padding-left 20px
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
  }
  .plan-bomcen-btn{
      height 100%
      padding-top 5px
      width 50%
      display flex
      align-items:center; /*实现垂直居中*/
  }
  .plan-bomcen-btn p{
      text-align center
  }
  .plan-bomcen-img img{
      width 80px
      height 80px
      border-radius 50%
  }
  .broadside{
      cursor pointer
      position fixed
      top 50%
      width 60px
      background color-primary
      text-align center
      border-radius 15px 0px 0px 15px
      right 0
  }
  .broadside p{
     color #fff
     padding 10px 10px
     font-size 25px
     word-wrap:break-word;
  }
  .dangerlist{
      width 90%
      height 100%
      margin 0 auto
  }
  .md-example-popup-right .md-scroll-view{
    width 100%
    height 100%
  }
  .dangerlistce{
      margin 20px 0
      height 100px
      background #fff
      border-radius 10px
  }
  .dangerlistce p{
      height 100%
      line-height 100px
  }
  .dangerlistcebom{
      position relative
      margin 30px 0
      background #fff
      border-bottom 1px solid #e0e0e0
  }
  .dangerlistcebombi{
    text-align left
  }
  .dangerlistcebombi p{
    line-height 80px
  }
  .dangerlistcebombi span{
    padding 0 10px
  }
  .dangerlistcebomb{
    border-bottom 1px solid #e0e0e0
  }
  .topclose{
    height 90px
    background-color color-primary
  }
  .topclose p{
    margin-left 30px
    color #fff
    height 90px
    line-height 90px
    float left
  }
  .close img{
      float right
      height 90px
      width 90px
  }
  .dangerlist>*{
    padding 0
  }
  .dangerlistcetop{
    height 100px
    border-bottom 1px solid #e0e0e0
    display flex
  }
  .dangerlistcetopl{
    width 90%
    text-align left
  }
  .dangerlistcetopl{
    display flex
  }
  .dangerlistcetoplspan{
    display inline-block
    height 60%
    width 10px
    margin 20px 10px
    background #8699ff
  }
  .dangerlistcetopr{
    width 10%
    position relative
  }
  .dangerlistcetopr img,.dangerlistcebom img,.bobrickr img,.bobrickl img{
    text-align center
    position absolute
    width 40px
    height 40px
    right 10px
    top 30px
  }
  .dangerlistcebom p{
    text-align left
  }
  .bobrick{
    display flex
    height 100px
    border-bottom 1px solid #e0e0e0
  }
  .bobrickl{
    width 20%
    position relative
  }
  .bobrickl img{
    left -10px
    margin 0px 10px
  }
  .bobrickl p{
    color #585858
  }
  .bobrickc{
    width 70%
  }
  .bobrickr{
    position relative
    width 10%
  }
  .bobrickl p, .bobrickc p{
    line-height 100px
  }
  .bobrickc p{
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  }
  .bobrickbom{
    height 90vh
  }
  .showmainbodyc{
    height 100vh
    background #fff
    width: 100vw;
  }
  .iconc{
    border-top 1px solid #fff
    color #fff
    padding 15px 0 0
    text-align center
  }
  .iconc::after{
    content: '分享'
    color white
    display block
    display flex
    justify-content center
    align-items center
    border-radius  0 0 10px 10px
    width 60px
    font-size 24px
    background-color color-primary
}

.showmainbodyc iframe {
  width: 100%;
  height: 100%;
}

.Currentpage {
  border-radius: 15px 0px 0px 15px;
}

.Nopage {
  border-radius: 15px 0px 0px 15px;
  background-color: #E0E0E0;
}
</style>
