/*
 * @Author: 王广婷
 * @Date: 2019-10-31 09:24:19
 * @LastEditors  : 贾峰
 * @LastEditTime : 2020-01-15 13:06:09
 */

import axios from '@/lib/api.request'

// 新建家庭
export const addFamliy = data =>
  axios.post('/abd/family/addfamily', data)

// 查询家庭列表
export const famliyList = params =>
  axios.get('/abd/family/list', { params })

// 添加家庭成员
export const addFamliyPerson = data =>
  axios.post('/abd/family/addfamilymembers', data)

// 删除家庭被保人
export const deleteInsured = data =>
  axios.post('/abd/family/delfamily', data)

// 添加家庭成员手动选择
export const usersearch = params =>
  axios.get('/abd/muser/userinsuredList', { params })

// 生成报告
export const examine = params =>
  axios.get('/abd/family/selectFamilyReport', { params })

// 查看报告/abd/family/selectFamilyReportList
export const guarantee = data =>
  axios.post('/abd/family/selectFamilyReportList', data)

// 上传保单图片
export const addpolicypic = data =>
  axios.post('/abd/abtComAbdPolicyImg/add', data)

// 家庭详情
export const getfamilydetails = params => axios.get('/abd/family/showFamilyBase', { params })

// 家庭成员信息带出
export const getfamilymember = data => axios.post('/abd/family/selectfamilymembers', data)

// 家庭保单分析获取reportno
export const getreportno = data => axios.post('/abd/family/selectListsByFamilyno', data)

// 添加家庭成员
export const addfamilymember = data => axios.post('/abd/family/addfamilymembers', data)

// 手动录入保单
export const addtxt = data => axios.post('/abd/abtComAbdPolicyImg/addTXT', data)

// 家庭成员列表
export const showfamilymember = params => axios.get('/abd/family/showfamily', { params })

// detail 查看家庭成员报告
export const lookAnalysis = params => axios.get('/abd/muser/showCustomer', { params })

// 生成家庭报告
export const createAnalysis = data => axios.post('/abd/muser/getAnalysis', data)

// 编辑家庭成员
export const editfamilymember = data => axios.put('/abd/muser/updateCustomer', data)

// 编辑保单报告备注
export const editpolicyremarks = data => axios.put('/abd/abtComAbdReportlist/updateRemarks', data)

// 分享查看家庭报告selectFamilyReport/{reportno}/{yzm}/{comid}
export const selectFamilyReport = params => axios.get('/abd/family/selectFamilyReport', { params })

// 分享查看个人报告/showCustomer/{yzm}/{reportno}/{comid}   客户报告
export const showCustomer = params => axios.get('/abd/muser/showCustomer', { params })

// 判断人员类型接口
export const judgeisCustomer = data => axios.post('/abd/muser/isCustomer', data)

// 查询家庭成员微信
export const selectfamwx = params => axios.get('/abd/muser/selectAllWxCusomer', { params })

// 保单报告客户存入信息
export const looksharereport = data => axios.post('/abd/muser/lookShareReport', data)

// 保存指定微信信息
export const savewxToCustomer = data => axios.post('/abd/muser/wxToCustomer', data)

// 获取家庭推荐二维码
export const getQrcode = params => axios.get('/abd/family/getQrcode', { params })

// 客户扫码建立关系
export const familyQrcode = data => axios.post('/abd/muser/familyQrcode', data)

// 客户查看家庭报告
export const selectReportData = data => axios.post('/abd/muser/selectReportData', data)

// 行为记录
export const actionRecord = data => axios.post('/abd/muser/actionRecord', data)

//家庭编辑
export const editFamily = data => axios.post('/abd/family/editFamily', data)

//删除家庭
export const delFamilyno = params => axios.delete('/abd/family/delFamilyno', { params })

//家庭合并
export const mergeFamily = data => axios.post('/abd/family/mergeFamily', data)

//家庭搜索名称
export const selectFamilyByname = data => axios.post('/abd/family/selectFamilyByname', data)

//生成报告
export const createFamilyReport = data => axios.post('/abd/family/createFamilyReport', data)


